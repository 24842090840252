<template>
  <vue-countdown
    v-if="remainTime > 0"
    :time="remainTime"
    :interval="1000"
    v-slot="{ days, hours, minutes, seconds }"
  >
    <div :style="{ maxWidth: maxWidth }" class="mx-auto text-center">
      <div class="red rounded-lg pa-1 my-1">
        تخفیف شگفت انگیز
      </div>
      <div class="d-flex mt-2">
        <div class="time-div rounded-lg pa-2 ml-2 red--text">
          <h2>{{ seconds }}</h2>
          <div class="time-title">ثانیه</div>
        </div>
        <div class="time-div rounded-lg pa-2 ml-2 red--text">
          <h2>{{ minutes }}</h2>
          <div class="time-title">دقیقه</div>
        </div>
        <div class="time-div rounded-lg pa-2 ml-2 red--text">
          <h2>{{ hours }}</h2>
          <div class="time-title">ساعت</div>
        </div>
        <div class="time-div red rounded-lg white--text pa-2">
          <h2>{{ days }}</h2>
          <div class="time-title white--text">روز</div>
        </div>
      </div>
    </div>
  </vue-countdown>
</template>

<script>
export default {
  props: {
    targetTime: {
      type: Date | String,
      required: true,
    },
    maxWidth: {
      type: String,
      default: "80%",
    },
    endDate: {
      type: String,
      required: true,
    },
  },
  //   data() {
  //     const now = new Date();
  //     const newYear = new Date(
  //       this.targetTime ? this.targetTime : now.getFullYear() + 1,
  //       0,
  //       1
  //     );

  //     return {
  //       time: newYear - now,
  //     };
  //   },
  data() {
    return {
      now: new Date(),
    };
  },
  computed: {
    remainTime: {
      get() {
        if (this.endDate) {
          console.log(
            new Date(this.endDate),
            this.now,
            new Date(this.endDate) - this.now
          );
          return new Date(this.endDate) - this.now;
        } else return 0;
      },
    },
  },
};
</script>

<style scoped>
.time-div {
  flex: 1;
  background-color: white;
  color: black;
}
.time-title {
  font-size: 10px;
  color: black;
}
</style>
